import Cookies, { CookieAttributes } from 'js-cookie'
import { ISetUp, cookies } from '@dto'
class CookieService {
  private setup: ISetUp

  constructor(setup: ISetUp) { this.setup = setup }

  private save(key: cookies.EKey, data: any, att: CookieAttributes): void {
    Cookies.set(`${this.setup.acronym}${key}`, data, att)
  }

  public remove(key: cookies.EKey, path?: string) {
    Cookies.remove(`${this.setup.acronym}${key}`, { path, domain: this.setup.domain })
  }

  public get<T>(key: cookies.EKey): T {
    const data = Cookies.get(`${this.setup.acronym}${key}`)
    return data ? JSON.parse(atob(data)) : data
  }

  public set(key: cookies.EKey, data: any, path?: string) {
    this.save(key, btoa(JSON.stringify(data)), { domain: this.setup.domain, path })
  }
}

export default CookieService
