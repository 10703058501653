import { Read } from './read'
import { ModuleDefault } from '../default'
import { IPageParams, ISetUp } from '@dto'

export class SubscriptionModule extends ModuleDefault {
  private _read: Read

  // Read
  getAll: (merchantId: string, params?: IPageParams) => Promise<any>
  getById: (merchantId: string, subscriptionId: string) => Promise<any>

  constructor(setup: ISetUp) {
    super(setup)
    this._read = new Read(setup)

    this.getAll = this._read.getAll
    this.getById = this._read.getById
  }
}
