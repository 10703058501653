import { ISetUp, TApplication } from '@dto'

class LocalService {
    private setup: ISetUp

     constructor(setup: ISetUp) { this.setup = setup }

    public has(key: TApplication): boolean {
        return key in localStorage
    }

    public get(key: TApplication): string {
        return localStorage.getItem(`${this.setup.acronym}mp_${key}`)
    }

    public getJson<T>(key: TApplication): T {
        const data = this.get(key)
        return data ? JSON.parse(atob(data)) : {}
    }

    public set(key: TApplication, value: string) {
        localStorage.setItem(`${this.setup.acronym}mp_${key}`, value)
    }

    public setJson(key: TApplication, value: any) {
        this.set(key, btoa(JSON.stringify(value)))
    }

    public remove(key: TApplication) {
        localStorage.removeItem(`${this.setup.acronym}mp_${key}`)
    }

    public clear() {
        localStorage.clear()
    }
}

export default LocalService
