import { merchant } from '@dto'
import { ModuleDefault } from '../default'

// TODO: Adicionar métodos
export class Create extends ModuleDefault {
  create(config: merchant.IConfig): Promise<any> {
    const body: merchant.ICreateBody = {} as merchant.ICreateBody
    body.name = config.name

    if (config.affiliate) {
      body.affiliate_mundipagg = true
      body.client_id = null
    } else {
      body.affiliate_mundipagg = false
      body.client_id = config.clientId
    }

    body.account_name = config.accountName
    body.create_accounts = config.createAccounts

    if (config.password !== undefined) { body.password = config.password }
    return this.request.post(`${this.setup.urlBase}/merchants`, body)
  }
}
