import { Read } from './read'
import { ModuleDefault } from '../default'
import { ISetUp } from '@dto'

export class CustomerModule extends ModuleDefault {
  private _read: Read

  // Read
  public getByMerchantId: (merchantId: string) => Promise<any>

  constructor(setup: ISetUp) {
    super(setup)
    this._read = new Read(setup)

    this.getByMerchantId = this._read.getByMerchantId
  }
}
