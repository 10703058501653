import { TLanguage, TTheme, TThemeMode } from './index.dto'

export enum EKey {
  preference = 'mp_pref',
  redirect = 'mp_rd',
  token = 'mp_tk',
  refresh_token = 'mp_rt'
}
export interface IPref {
  timezone: string
  language: TLanguage
  appearance: {
    theme: TTheme,
    mode: TThemeMode
  }
}
