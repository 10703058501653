import { Read } from './read'
import { Update } from './update'
import { Create } from './create'
import { ModuleDefault } from '../default'
import { IPageParams, ISetUp, merchant, TApplication } from '@dto'

export class MerchantModule extends ModuleDefault {
  private _read: Read
  private _update: Update
  private _create: Create

  // Read
  public getAll: (params?: IPageParams) => any
  public getById: (merchantId: string) => Promise<merchant.IGeneralData>
  public getAMK: (merchantId: string, body: any) => any
  public getSummary: (merchantId: string, applicationName: TApplication, envVariables: any) => Promise<merchant.IGetSummary>
  public getInsights: (merchantId: string, params?: Record<string, any>) => any
  public getBestAccounts: (merchantId: string, params?: Record<string, any>) => any
  public getAllowedIps: (merchantId: string, params?: Record<string, any>) => Promise<merchant.IAllowedIps>

  // Update
  public update: (merchantId: string, body: any) => Promise<any>
  public updateSettings: (merchantId: string, body: any) => Promise<any>
  public updateAllowedIps: (merchantId: string, body: merchant.IAllowedIps) => Promise<merchant.IAllowedIps>

  // Create
  public create: (config: merchant.IConfig) => Promise<any>

  constructor(setup: ISetUp) {
    super(setup)
    this._read = new Read(setup)
    this._update = new Update(setup)
    this._create = new Create(setup)

    this.getAll = this._read.getAll
    this.getById = this._read.getById
    this.getAMK = this._read.getAMK
    this.getSummary = this._read.getSummary
    this.getInsights = this._read.getInsights
    this.getBestAccounts = this._read.getBestAccounts
    this.getAllowedIps = this._read.getAllowedIps

    this.update = this._update.update
    this.updateSettings = this._update.updateSettings
    this.updateAllowedIps = this._update.updateAllowedIps

    this.create = this._create.create
  }
}
