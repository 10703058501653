import { ModuleDefault } from '../default'
import { IPageParams } from '@dto'

export class Read extends ModuleDefault {
  public getAll(merchantId: string, params: IPageParams = {}): Promise<any> {
    params.page = params.page || 1
    params.size = params.size || 10
    const queryParams = this.utils.paramSerializer(params)
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/accounts${queryParams}`)
  }

  public getById(merchantId: string, accountId: string) {
    return this.request.get(`${this.setup.urlDash}/merchants/${merchantId}/accounts/${accountId}`)
  }
}
